@font-face{
    font-family:"Galeb Texture W01 One";
    src:url("/fonts/5ae15055-cc38-4b95-ae24-ed80b83702a9.woff2") format("woff2"),url("/fonts/8ebd99b8-313e-4eba-b925-ad2643c47874.woff") format("woff");
}
@font-face{
    font-family:"Hand Stamp Slab Serif";
    src:url("/fonts/62c9aba5-2a65-4f4c-9893-67b59d7522bc.woff2") format("woff2"),url("/fonts/4a8bc729-3eb6-4e5c-9d15-791c72bd589b.woff") format("woff");
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Galeb Texture W01 One', sans-serif;
  line-height: 1.25;
  margin: 0
}

/* Use span or div if you don't need this spacing */
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6,
* + ul,
* + ol,
* + p {
  margin-top: 1em;
}

.text-accent {
  font-family: 'Hand Stamp Slab Serif', serif;
  text-transform: uppercase;
}

.text-accent-2 {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.125em;
  font-weight: bold;
  text-transform: uppercase;
}

.font-expanded {
  font-stretch: expanded;
}

.text-responsive {
  font-size: clamp(100%, 3vw, 150%);
}