:root {
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s6: calc(var(--s5) * var(--ratio));
}

.__akia-chat-icon-wrapper {
  bottom: 170px!important;
  right: 30px!important;
}

.grecaptcha-badge {
  visibility: hidden;
}

html {
  box-sizing: border-box;

  font-size: 16px;
  line-height: 1.625;
  font-family:'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  width: 100%;
}

#root {
  overflow-x: hidden;
}

* {
  box-sizing: inherit;
}

/* ========================= Component Styles ========================= */

.break-out-banner {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

@media screen and (min-width: 1024px) {
  .hide-above-lg {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .hide-on-lg {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .hide-above-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-on-md {
    display: none;
  }

  .page-title {
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    right: 0;
    width: fit-content;
    text-align: center;
  }
}

@media screen and (max-width: 640px) {
  .hide-on-sm {
    display: none;
  }
}